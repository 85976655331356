<template>
    <v-container>


        <!-- Not Logged -->

        <div v-if="!isLogged" style="width=100%" class="mt-12 d-flex flex-column align-center text-center">
            <v-card color="" width="600">
                <h1 class="mt-6 mb-12">
                    {{ $t('login.title') }}
                </h1>

                <v-form class="px-6" @submit="login()">
                    <v-text-field v-model.trim="username" :color="negative_color_class"
                        :label="$t('login.field.username')" outlined />
                    <v-text-field type="password" v-model="password" :color="negative_color_class"
                        :label="$t('login.field.password')" outlined />
                    <div>
                        {{ loginError }}
                    </div>
                </v-form>


                <div class="px-6 mt-12 mb-6">
                    <v-btn class="" block color="secondary" @click="login()" type="submit">
                        <span class="primary--text">
                            {{ $t('login.button') }}
                        </span>
                    </v-btn>
                    <br>
                    <v-btn outlined color="primary" :to="{ name: 'PasswordReset' }">
                        <span class="secondary--text">
                            {{ $t('passwordforgot.btn.request') }}
                        </span>
                    </v-btn>
                </div>

            </v-card>
        </div>




        <v-row>
            <v-col cols=12>
                <!-- Not Logged -->

                <!-- <v-card v-if="!isLogged" :color="positive_color_class" width="400">
                    <v-card-text>
                        <h1 class="mb-12">{{ $t('login.title') }}</h1>
                        <form>
                            <v-text-field v-model.trim="username" :color="negative_color_class"
                                :label="$t('login.field.username')" outlined />
                            <v-text-field type="password" v-model="password" :color="negative_color_class"
                                :label="$t('login.field.password')" outlined />
                        </form>
                        <div>
                            {{ loginError }}
                        </div>

                    </v-card-text>
                    <v-card-actions v-if="!isLogged">
                        <v-btn block color="secondary" @click="login()">
                            <span class="primary--text">
                                {{ $t('login.button') }}
                            </span>
                        </v-btn>
                    </v-card-actions>
                    <v-row v-if="!isLogged">
                        <v-col cols=12 class="text-right">
                            <v-btn outlined color="primary" :to="{ name: 'PasswordReset' }">
                                <span class="secondary--text">
                                    {{ $t('passwordforgot.btn.request') }}
                                </span>
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-card> -->


                <!-- When logged in -->
                <div v-if="isLogged" style="width=100%" class="mt-12 d-flex flex-column align-center text-center">
                    <v-card color="" width="600">
                        <h1 class="mt-6 mb-6">
                            {{ $t('login.title') }}
                        </h1>
                        <div class="text-center ml-6 d-block mb-6 caption">{{ $t('login.legged_as', {
                                username:
                                    myUsername
                            })
                        }}
                        </div>

                        <v-row class="px-6 text-center" justify="center">
                            <v-col v-if="isContactManager || isAdmin" cols="12" sm="4"
                                class="d-flex flex-column justify-center align-center">
                                <v-card elevation="12" :to="{ name: 'ListContacts' }" color="primary lighten-4"
                                    width="180" height="180"
                                    class="text-center d-flex flex-row justify-center align-center">
                                    <v-card-text class="secondary--text">
                                        <v-icon style="font-size:6rem;" color="secondary" x-large> mdi-account-multiple
                                        </v-icon>
                                        <div>
                                            {{ $t('adminpanel.list_contacts') }}
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col v-if="isEditor || isAdmin" cols="12" sm="4"
                                class="d-flex flex-column justify-center align-center">
                                <v-card elevation="12" :to="{ name: 'ListPosts' }" color="primary lighten-4" width="180"
                                    height="180" class="text-center d-flex flex-row justify-center align-center">
                                    <v-card-text class="secondary--text">
                                        <v-icon style="font-size:6rem;" color="secondary" x-large> mdi-note-edit
                                        </v-icon>
                                        <div>
                                            {{ $t('adminpanel.list_posts') }}
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col v-if="isAdmin" cols="12" sm="4"
                                class="d-flex flex-column justify-center align-center">
                                <v-card elevation="12" :to="{ name: 'ListAccounts' }" color="primary lighten-4"
                                    width="180" height="180"
                                    class="text-center d-flex flex-row justify-center align-center">
                                    <v-card-text class="secondary--text">
                                        <v-icon style="font-size:6rem;" color="secondary" x-large> mdi-account-group
                                        </v-icon>
                                        <div>
                                            {{ $t('adminpanel.list_users') }}
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>


                        <div class="px-6 mt-12 mb-6">
                            <v-row no-gutters>
                                <v-col cols="6" class="text-left">
                                    <v-btn outlined :color="positive_color_class" :to="{ name: 'PasswordChange' }">
                                        <span :style="negative_color_style">
                                            {{ $t('admin.passwordChange') }}
                                        </span>
                                    </v-btn>
                                </v-col>
                                <v-col cols="6" class="text-right">
                                    <v-btn outlined :color="positive_color_class" @click="logout()">
                                        <span v-if="$vuetify.breakpoint.smAndUp" :style="negative_color_style">
                                            {{ $t('admin.logout') }}
                                        </span>
                                        <v-icon :color="negative_color_class" v-if="!$vuetify.breakpoint.smAndUp">mdi-logout</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                </div>



                <!-- 
                <v-card v-if="isLogged" :color="positive_color_class" max-width="800">

                    <v-card-text>
                        <h1 class="mb-2">{{ $t('login.title') }}</h1>

                        <span class="d-block mb-6">{{ $t('login.legged_as', { username: myUsername }) }}</span>

                        <v-row>
                            <v-col cols="12" sm="4" class="d-flex flex-column justify-center align-center">
                                <v-card elevation="12" :to="{ name: 'ListContacts' }" color="primary lighten-4"
                                    width="200" height="200"
                                    class="text-center d-flex flex-row justify-center align-center">
                                    <v-card-text class="secondary--text">
                                        <v-icon style="font-size:6rem;" color="secondary" x-large> mdi-account-multiple
                                        </v-icon>
                                        <div>
                                            {{ $t('adminpanel.list_contacts') }}
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="4" class="d-flex flex-column justify-center align-center">
                                <v-card elevation="12" :to="{ name: 'ListPosts' }" color="primary lighten-4" width="200"
                                    height="200" class="text-center d-flex flex-row justify-center align-center">
                                    <v-card-text class="secondary--text">
                                        <v-icon style="font-size:6rem;" color="secondary" x-large> mdi-note-edit
                                        </v-icon>
                                        <div>
                                            {{ $t('adminpanel.list_posts') }}
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="4" class="d-flex flex-column justify-center align-center">
                                <v-card elevation="12" :to="{ name: 'ListAccounts' }" color="primary lighten-4"
                                    width="200" height="200"
                                    class="text-center d-flex flex-row justify-center align-center">
                                    <v-card-text class="secondary--text">
                                        <v-icon style="font-size:6rem;" color="secondary" x-large> mdi-account-group
                                        </v-icon>
                                        <div>
                                            {{ $t('adminpanel.list_users') }}
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn outlined :color="positive_color_class" :to="{ name: 'PasswordChange' }">
                            <span :style="negative_color_style">
                                {{ $t('admin.passwordChange') }}
                            </span>
                        </v-btn>

                        <v-spacer></v-spacer>
                        <v-btn outlined :color="positive_color_class" @click="logout()">
                            <span :style="negative_color_style">
                                {{ $t('admin.logout') }}
                            </span>
                        </v-btn>
                    </v-card-actions>
                </v-card> -->
            </v-col>
        </v-row>
    </v-container>
</template>

<script>


export default {
    props: {},
    components: {},
    data: () => ({
        username: '',
        password: '',
        login_disable: false,
        loginError: null
    }),
    methods: {
        login() {

            if (this.username == "") {
                alert(this.$t('login.error.no_username'))
                return
            }
            if (this.password == "") {
                alert(this.$t('login.error.no_password'))
                return
            }

            this.$store.dispatch("auth/act_login", { username: this.username, password: this.password }).then(() => {
                console.log('end login');

                if (this.isLocked) {
                    this.$route.push({ name: 'Locked' })
                }
                // this.$route.push({ name: 'Login' })
            }).catch(error => {
                console.log(error);
            })
        },
        logout() {
            this.$store.dispatch("auth/act_logout").then(() => {
            })
        }
    },
    mounted() {
        // Load last username from localstorage
        this.username = localStorage.getItem('last_login_username')
    },
    created() { },
    computed: {},
    watch: {}
}
</script>

<style>

</style>