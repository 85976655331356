<template>
    <v-container>

        <div style="width=100%" class="mt-12 d-flex flex-column align-center text-center">
            <v-card color="red" width="600">
                <h1 class="mt-12 black--text">
                    {{ $t('unauthorized.title') }}
                </h1>
                <p class="black--text"  v-html="$t('unauthorized.description')">
                </p>
                <div class="mt-12 mb-12">
                    <v-btn color="white" :to="{name: 'Admin'}">
                        <span class="black--text">
                            {{ $t('unauthorized.back') }}
                        </span>
                    </v-btn>
                </div>
            </v-card>
        </div>

    </v-container>
    
</template>

<script>
export default {

}
</script>

<style>

</style>